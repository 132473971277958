@tailwind base;
@tailwind components;
@tailwind utilities;

@custom-variant dark (&:where([data-theme=dark], [data-theme=dark] *));

@layer utilities {
  .perspective-1000 {
    perspective: 1000px;
  }

  .backface-hidden {
    backface-visibility: hidden;
  }
}

@font-face {
  font-family: 'Inter';
  src: url("assets/fonts/Inter-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}


body {
  font-family: "Inter", sans-serif;
}

.border-gradient {
  border-width: 4px;
  border-style: solid;
  height: 429px;
  border-image: linear-gradient(to top,
      #fff,
      #0a101800 1%,
      #c8e64c99 60%,
      #467aff 100%) 1;
  background: linear-gradient(to top,
      rgba(244, 247, 255, 0) 0%,
      rgba(244, 247, 255, 1) 100%);
}

.scan-store-bg {
  background: radial-gradient(circle at right,
      #467aff 0%,
      #1a2f86 47.46%,
      #15172d 100%);
}

.scan-store-bg-small {
  background: var(--USA-Berze-Gradient,
      radial-gradient(circle, #467aff 0%, #1a2f86 47.46%, #15172d 100%));
}

.forex-section-bg {
  background: radial-gradient(68.21% 57.57% at 45.49% 41.46%,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.8) 56.25%,
      rgba(255, 255, 255, 0) 100%),
    url("/src/assets/forex-hero-cover.png"), lightgray 50% / cover no-repeat;
}

.forex-hero-cover {
  background: radial-gradient(68.21% 57.57% at 45.49% 41.46%,
      #fff 0%,
      #fff 56.25%,
      rgba(255, 255, 255, 0) 100%),
    url("/src/assets/forex-hero-cover.png"), lightgray 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.forex-section-small-bg {
  background-image: url("/src/assets/forexBgSmall.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 1024px) {
  .border-gradient {
    height: 320px;
    border-width: 2px;
  }

  .scan-store-bg {
    background: radial-gradient(168.58% 42.54% at 57.22% 50.71%,
        #467aff 0%,
        #1a2f86 47.46%,
        #15172d 100%);
  }

  .forex-section-small-bg {
    background-image: url("/src/assets/forexBg_mobile.png");
  }

  .forex-hero-cover {
    background: radial-gradient(98.11% 82.8% at 50.13% 50.05%,
        #fff 0%,
        #fff 50.5%,
        rgba(255, 255, 255, 0) 100%),
      url("/src/assets/forex-hero-cover.png"), lightgray 50% / cover no-repeat;
  }
}

@media (max-width: 1024px) {
  .forex-section-bg {
    background: linear-gradient(0deg,
        rgba(255, 255, 255, 0.85) 0%,
        rgba(255, 255, 255, 0.85) 100%),
      url("/src/assets/forex-hero-cover.png"), lightgray 50% / cover no-repeat;
  }
}

@media (min-width: 2161px) {
  .usa-stock-graph {
    width: 80% !important;
    bottom: -100px;
  }
}

@media (min-width: 2300px) {
  .usa-stock-graph {
    bottom: -200px;
  }
}

@media (min-width: 2850px) {
  .usa-stock-graph {
    bottom: -300px;
  }
}

.montenegro-stock-bg {
  background: radial-gradient(at top right, #89b8b6 0%, #106562 100%);
}

.question-tab-bg {
  /* background: radial-gradient(ellipse at center, #F5F8FF 0%, #E9EFFF 100%); */
  background: linear-gradient(to right, #e9efff 20%, #f5f8ff 100%);
}

.question-answer {
  transition: max-height 0.5s ease-out;
}

.about-section-bg {
  background-image: url("/src/assets/Hero-AboutUs.png");
}

@media (max-width: 550px) {
  .about-section-bg {
    background-image: url("/src/assets/Hero-AboutUs-mobile.png");
  }
}

.education-card-one-bg {
  background: linear-gradient(to top, #dee6fb 0%, #d9e1f9 100%);
}

.education-card-two-bg {
  background: radial-gradient(circle, #faffe4 0%, #e0f38e 100%);
}

.search-bg {
  background: linear-gradient(to right, #ffffff 14%, #d9e1f9, #ffffff 94%);
}

.stocks-bg {
  background: linear-gradient(to right,
      #293d84 14%,
      /* Tamnija nijansa */
      #293b84 50%,
      /* Originalna boja */
      #293a74 94%);
  /* Svetlija nijansa */
}

.newsletter-bg {
  background-color: #c8e64c;
  background-image: -webkit-linear-gradient(-74deg,
      #c8e64c 0%,
      #c8e64c 50%,
      #2c43a5 50%,
      #2c43a5 50%);
  background-image: linear-gradient(-74deg,
      #c8e64c 0%,
      #c8e64c 50%,
      #2c43a5 50%,
      #2c43a5 50%);
}

.availability-bg {
  background-color: #c8e64c;
  background-image: -webkit-linear-gradient(-90deg,
      #fff 0%,
      #fff 47%,
      #c8e64c 47%,
      #c8e64c 47%);
  background-image: linear-gradient(-90deg,
      #fff 0%,
      #fff 47%,
      #c8e64c 47%,
      #c8e64c 47%);
}

@media (max-width: 2000vpx) {
  .availability-bg {
    background-image: -webkit-linear-gradient(-90deg,
        #fff 0%,
        #fff 45%,
        #c8e64c 45%,
        #c8e64c 45%);
    background-image: linear-gradient(-90deg,
        #fff 0%,
        #fff 45%,
        #c8e64c 45%,
        #c8e64c 45%);
  }
}

@media (max-width: 1279px) {
  .newsletter-bg {
    background-color: #2c43a5;
    background-image: none;
  }
}

@media (max-width: 1023px) {
  .availability-bg {
    background-color: #c8e64c;
    background-image: none;
  }
}

.carouselWrapper {
  position: relative;
  overflow: hidden;
  margin: auto;
  box-sizing: border-box;
}

.imgWrap {
  position: "absolute";
  top: 0;
  left: 0;
}

.imgWrap:hover {
  cursor: pointer;
}

li {
  list-style-type: none;
}

.dn {
  display: none;
}

.first-slide-hero {
  position: relative;
  background: radial-gradient(circle at center, #467aff 0%, #1a2f86 100%);
}

.first-slide-hero::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left: 70px solid transparent;
  border-top: 40px solid #c8e64c;
}

.forex-card::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left: 90px solid transparent;
  border-top: 60px solid #c8e64c;
}

.second-slide-hero {
  position: relative;
  background: linear-gradient(0deg,
      rgba(217, 217, 217, 0),
      rgba(217, 217, 217, 0)),
    radial-gradient(80% 100% at 78.12% 31.38%,
      #89b8b6 0%,
      #89b8b6 20%,
      #106562 100%);
}

.second-slide-hero::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left: 70px solid transparent;
  border-top: 40px solid #c8e64c;
}

.third-slide-hero {
  position: relative;
  background: radial-gradient(circle at center, #192e86 0%, #203a5e 100%);
}

.third-slide-hero::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  width: 0;
  height: 0;
  border-left: 70px solid transparent;
  border-top: 40px solid #c8e64c;
}

.hero-slider .swiper-pagination-bullet {
  @apply bg-[#CED5DF] cursor-pointer transition-colors duration-300 ease-in-out w-[10px] h-[10px] -skew-x-[4deg] rounded-none !important;
}

.hero-slider .swiper-pagination-bullet-active {
  @apply bg-[#3e6be4] !important;
}

.hero-slider .swiper-pagination {
  @apply bottom-[-46px] !important;
}

.hero-slider .swiper {
  @apply overflow-visible !important;
}

.hero-slider .bigger {
  @apply block;
}

.hero-slider .smaller {
  @apply hidden;
}

@media (max-width: 640px) {
  .hero-slider .bigger {
    @apply hidden;
  }

  .hero-slider .smaller {
    @apply block;
  }

  .hero-main {
    @apply pb-[90px];
  }
}

.capital-products-slider .swiper {
  @apply overflow-visible w-screen max-w-screen-2xl !important;
}

.capital-products-slider .swiper-slide {
  @apply lg:w-[750px] md:w-auto md:mr-[25px] mr-0 w-full pl-[16px] pr-[16px] md:px-0 !important;
}

.capital-products-slider .swiper-pagination-bullet {
  @apply bg-[#CED5DF] cursor-pointer transition-colors duration-300 ease-in-out w-[10px] h-[10px] -skew-x-[4deg] opacity-100 rounded-none !important;
}

.capital-products-slider .swiper-pagination-bullet-active {
  @apply bg-[#3e6be4] !important;
}

.capital-products-slider .swiper-pagination {
  @apply bottom-[-46px] !important;
}

.button-big {
  @apply leading-[12px] text-[12px] font-medium lg:min-w-[190px] text-center w-auto lg:px-[24px] py-[14px] min-w-[161px] px-[16px] rounded-[1px];
}

.button-small {
  @apply leading-[14px] text-[12px] font-bold px-[24px] py-[9px] text-center min-w-[140px] rounded-[1px];
}

.button-primary {
  @apply bg-[#C8E64C] text-[#163A69] transition-all ease-in-out duration-300;
  border: 1.5px solid rgba(22, 58, 105, 0);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}

.button-primary:active {
  @apply bg-[#E6FF6A];
  border: 1.5px solid rgba(22, 58, 105, 0);
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1);
}

.button-primary:hover {
  @apply bg-[#DCF560];
  border: 1.5px solid transparent;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.button-secondary {
  @apply text-white bg-transparent border-[1px] border-solid border-[#C8E64C] transition-all ease-in-out duration-300;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}

.button-secondary:hover {
  @apply text-[#DCF560] border-white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.button-tertiary {
  @apply text-black bg-transparent border-[1px] border-solid border-[#3E6BE4] transition-all ease-in-out duration-300;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}

.button-tertiary:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.card-gradient {
  background: radial-gradient(circle, #2D63FF 0%, #181F49 90%);
}

@media only screen and (min-width: 280px) {
  .blog-title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 375px) {
  .blog-title {
    font-size: 32px;
  }
}

@media only screen and (min-width: 640px) {
  .blog-title {
    font-size: 56px;
  }
}

.hover-color:hover {
  color: #f2f2f2;
}

.hover-color-dark:hover {
  color: #404650;
}

/* lg:w-[380px] sm:w-[250px] w-[150px] */

@keyframes slideAnimation {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

.looper {
  width: 100%;
  overflow: hidden;
}

.looper__innerList {
  display: flex;
  justify-content: center;
  width: fit-content;
}

.looper__listInstance {
  display: flex;
  width: max-content;

  animation: slideAnimation linear infinite;
}

.looper:hover .looper__listInstance {
  animation-play-state: paused !important;
}

.looper .looper__listInstance {
  animation-play-state: running;
}

.looper__innerList[data-animate="true"] .looper__listInstance {
  animation: slideAnimation linear infinite;
}

.looper__listInstance {
  display: flex;
  width: max-content;

  animation: none;
}

.header-bg {
  backdrop-filter: blur(4px);
  background: linear-gradient(90deg,
      #ffffff 0%,
      #ffffff 35%,
      #ffffff 35%,
      #ffffff 100%);
}

html {
  scroll-behavior: smooth;
}

.aboutSlideOne {
  max-width: 120%;
  width: 120%;
}

.aboutSlideTwo {
  width: 120%;
  max-width: 115%;
}

.slide-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.slide-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slide-enter {
  animation: slideIn 0.5s forwards;
}

.slide-exit {
  animation: slideOut 0.5s forwards;
}

.qr-code-background {
  background: #163a69;
  box-shadow: 4px 4px 14px 0px rgba(0, 0, 0, 0.2);
}

.markdown>* {
  all: revert;
}

.markdown ul li {
  list-style-type: disc !important;
}

.markdown ol li {
  list-style-type: auto !important;
}

.markdown ol li:marker {
  color: #d1d5db;
}

.markdown ul li:marker {
  color: #d1d5db;
}

.markdown h1 {
  color: var(--Black, #000);
  font-size: 44px;
  font-weight: 600 !important;
  line-height: 56px;
  /* 127.273% */
}

.markdown h2 {
  color: var(--Black, #000);
  font-size: 28px;
  font-weight: 600 !important;
  line-height: 56px;
  /* 127.273% */
}

@media only screen and (min-width: 280px) {
  .markdown {
    padding-left: 10px;
    padding-right: 10px;
  }

  .markdown h1 {
    color: var(--Black, #000);
    font-size: 28px;
    font-weight: 600 !important;
    line-height: 36px;
    /* 127.273% */
  }

  .markdown h2 {
    color: var(--Black, #000);
    font-size: 24px;
    font-weight: 600 !important;
    line-height: 32px;
    /* 127.273% */
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}